<template>
  <div class="mapBox" id="map" ref="containerBox">
    <div class="VRBox" v-if="vrUrl">
      <img
        class="closeImg"
        @click="close()"
        src="@/assets/img/close.png"
        alt=""
      />
      <iframe class="playBox" :src="vrUrl" frameborder="0"></iframe>
    </div>
    <div class="bigTitle">武夫三维地图</div>
    <div class="allBtn">
      <div class="oneBtn" @click="flyReturnBack()">
        <div class="btnImg">
          <img src="@/assets/img/fuwei.png" alt="" />
        </div>
        <div class="btnText">复位</div>
      </div>
      <div class="oneBtn" @click="test(1)">
        <div :class="type == 'SPACE_AREA' ? 'btnImg' : 'btnImg1'">
          <img src="@/assets/img/cemian.png" alt="" />
        </div>
        <div :class="type == 'SPACE_AREA' ? 'btnText' : 'btnText1'">测面</div>
      </div>
      <div class="oneBtn" @click="test(0)">
        <div :class="type == 'SPACE_DISTANCE' ? 'btnImg' : 'btnImg1'">
          <img src="@/assets/img/ceju.png" alt="" />
        </div>
        <div :class="type == 'SPACE_DISTANCE' ? 'btnText' : 'btnText1'">
          测距
        </div>
      </div>
      <div class="oneBtn" @click="switchMap()">
        <div class="btnImg"><img src="@/assets/img/切换.png" alt="" /></div>
        <div class="btnText">切换地图</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: {},
  setup() {
    const _this = getCurrentInstance().proxy;
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      count: 0,
      vrUrl: false,
      json: [
        {
          id: 1,
          name: "榕树+古会场",
          position: [120.0164861, 26.8121144443, 12],
          url: "https://720yun.com/t/32vkzhrqs1l?scene_id=85156345",
        },
        {
          id: 1,
          name: "道观",
          position: [120.0213061111, 26.8048369444, 236],
          url: "https://720yun.com/t/32vkzhrqs1l?scene_id=85156344",
        },
        {
          id: 3,
          name: "古县洋",
          position: [120.0136733334, 26.8152238887, 286],
          url: "https://720yun.com/t/32vkzhrqs1l?scene_id=85156347",
        },
        {
          id: 4,
          name: "八角塔",
          position: [120.0191877778, 26.8140238888, 42],
          url: "https://720yun.com/t/32vkzhrqs1l?scene_id=85156346",
        },
        {
          id: 5,
          name: "孙丽美落水点",
          position: [120.0023830556, 26.8114777777, 13],
          url: "https://720yun.com/t/32vkzhrqs1l?scene_id=85156348",
        },
        {
          id: 6,
          name: "葛洪山顶",
          position: [120.0210236111, 26.8043080556, 706],
          url: "https://720yun.com/t/32vkzhrqs1l?scene_id=85156350",
        },
        {
          id: 7,
          name: "村委楼",
          position: [120.0153952779, 26.8120302778, 14],
          url: "https://720yun.com/t/32vkzhrqs1l?scene_id=85156351",
        },
        {
          id: 8,
          name: "古县洋",
          position: [120.0118638888, 26.8196838889, 288],
          url: "https://720yun.com/t/32vkzhrqs1l?scene_id=85156349",
        },
      ],

      show: 1,
    });
    //测距测面
    const test = (i) => {
      window.map.getDefaultContextMenu()[5].children[i].callback();
    };
    const drawMap = () => {
      const _this = this;
      const map = new mars3d.Map("map", {
        control: {
          geocoder: true,
          // geocoderConfig: {
          //   key: [
          //     "f2fedb9b08ae13d22f1692cd472d345e",
          //     "2e6ca4aeb6867fb637a5bee8333e5d3a",
          //   ], // 高德key
          // },
        },
        scene: {
          //默认视角参数
          // center: {
          //   lat: 27.649868,
          //   lng: 117.974115,
          //   alt: 558.4,
          //   // alt: 1000,
          //   heading: 296,
          //   pitch: -88,
          // },
          center:{lat: 27.613428, lng: 118.253639, alt: 5378, heading: 260, pitch: -51},
          shadows: false, //是否启用日照阴影
          removeDblClick: true, //是否移除Cesium默认的双击事件

          //以下是Cesium.Viewer所支持的options【控件相关的写在另外的control属性中】
          sceneMode: 3, //3等价于Cesium.SceneMode.SCENE3D,

          //以下是Cesium.Scene对象相关参数
          showSun: true, //是否显示太阳
          showMoon: true, //是否显示月亮
          showSkyBox: true, //是否显示天空盒
          showSkyAtmosphere: true, //是否显示地球大气层外光圈
          fog: true, //是否启用雾化效果
          fxaa: true, //是否启用抗锯齿

          //以下是Cesium.Globe对象相关参数
          globe: {
            depthTestAgainstTerrain: false, //是否启用深度监测
            baseColor: "#546a53", //地球默认背景色
            showGroundAtmosphere: true, //是否在地球上绘制的地面大气
            enableLighting: false, //是否显示昼夜区域
          },
          //以下是Cesium.ScreenSpaceCameraController对象相关参数
          cameraController: {
            zoomFactor: 3.0, //鼠标滚轮放大的步长参数
            minimumZoomDistance: 1, //地球放大的最小值（以米为单位）
            maximumZoomDistance: 50000000, //地球缩小的最大值（以米为单位）
            enableRotate: true, //2D和3D视图下，是否允许用户旋转相机
            enableTranslate: true, //2D和哥伦布视图下，是否允许用户平移地图
            enableTilt: true, // 3D和哥伦布视图下，是否允许用户倾斜相机
            enableZoom: true, // 是否允许 用户放大和缩小视图
            enableCollisionDetection: true, //是否允许 地形相机的碰撞检测
          },
        },
        // terrain: {
        //   type: "tdt",
        //   url: "//data.mars3d.cn/terrain",
        //   key: mars3d.Token.tianditu,
        //   subdomains: "01234567",
        //   show: true,
        // },

        basemaps: [
          {
            id: 2021,
            pid: 10,
            name: "天地图影像",
            icon: "img/basemaps/tdt_img.png",
            type: "group",
            layers: [
              {
                name: "底图",
                type: "tdt",
                layer: "img_d",
                key: ["c1f9e9262794df6d4bddbbe3c1f93732"],
              },
              {
                name: "注记",
                type: "tdt",
                layer: "img_z",
                key: ["c1f9e9262794df6d4bddbbe3c1f93732"],
              },
            ],
            show: true,
          },

          {
            name: "武夷山航拍瓦片图",
            url: "https://npmap.3dy.me/wys/{z}/{x}/{y}.png",
            type: "xyz",
            // proxy: "//server.mars3d.cn/proxy/",
            show: false,
          },
          {
            name: "南平市卫星地图瓦片图",
            url: "https://npmap.3dy.me/np_map/{z}/{x}/{y}.jpg",
            type: "xyz",
            // minimumLevel: 6,
            // maximumLevel: 18,
            show: true,
            // proxy: "http://server.mars3d.cn/proxy/",
            chinaCRS: mars3d.ChinaCRS.GCJ02,
          },
        ],

        layers: [
          {
            id: 20601122,
            type: "3dtiles",
            name: "武夷山三维模型",
            // url: "https://npmap.3dy.me/np_3d/wys_c/tileset.json",
            url: "https://npmap.3dy.me/3dtiles/wufu/tileset.json",
            scale: 1,
            show: true,
            maximumScreenSpaceError: 1,
            maximumMemoryUsage: 512,
            dynamicScreenSpaceError: true,
            cullWithChildrenBounds: false,
            skipLevelOfDetail: false,
            preferLeaves: false,
            flyTo: true,
          },
          // {
          //   type: "3dtiles",
          //   name: "测试模型",
          //   url: "https://npmap.3dy.me/wys/tileset.json",
          //   proxy: "//server.mars3d.cn/proxy/",
          //   show: true,
          //   flyTo: true,
          // },
        ],
      });

      window.map = map;
      window.map.on(mars3d.EventType.click, function (params) {
        let hudu = Cesium.Cartographic.fromCartesian(params.cartesian);
        let lnglat = mars3d.LatLngPoint.fromCartesian(params.cartesian);
      });
      window.viewer = map.viewer;
      map.terrainProvider = mars3d.LayerUtil.createTerrainProvider({
        url: "//data.mars3d.cn/terrain",
      });
      map.on(
        mars3d.EventType.leftDown,
        (event) => {
          map.cancelFlyTo();
        },
        this
      );
      return map;
    };
    const flyReturnBack = () => {
      window.map.flyHome();
    };
    //地图切换
    const switchMap = () => {
      if (state.show == 1) {
        if (window.map.getLayer("武夷山航拍瓦片图", "name").state == "inited") {
          window.map.getLayer("武夷山航拍瓦片图", "name").addTo(window.map);
        }
        map.getLayer("武夷山航拍瓦片图", "name").show = true;
        map.getLayer("武夷山三维模型", "name").show = false;
        map.getLayer("南平市卫星地图瓦片图", "name").show = false;
        state.show = 2;
        return;
      }
      map.getLayer("武夷山航拍瓦片图", "name").show = false;
      map.getLayer("武夷山三维模型", "name").show = true;
      map.getLayer("南平市卫星地图瓦片图", "name").show = true;
      state.show = 1;
    };
    const close = () => {
      state.vrUrl = false;
    };
    onMounted(() => {
      drawMap();
    });
    return {
      state,
      //方法
      flyReturnBack,
      test,
      switchMap,
      close,
    };
  },
};
</script>
<style lang="less" scoped>
.mapBox {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
}
.bigTitle {
  position: absolute;
  top: 3vh;
  left: 3vh;
  z-index: 10;
  width: auto;
  padding-right: 6vh;
  padding-left: 1.5vh;
  height: 8vh;
  line-height: 8vh;
  font-size: 5vh;
  color: #fff;
  letter-spacing: 0.1em;
  font-weight: 700;
  font-family: "\5FAE\8F6F\96C5\9ED1";
  text-shadow: 0.5vh 0.5vh 0.5vh rgb(5 5 5 / 90%);
  -webkit-animation: mainTitleFrame-data-v-d66bfe98 2s linear;
  animation: mainTitleFrame-data-v-d66bfe98 2s linear;
}
.allBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 8;
  right: 2%;
  bottom: 2%;
  .oneBtn {
    width: 52px;
    height: 52px;
    margin: 16px 0 0 0;
    background: rgba(0, 0, 0, 0.6);
    border: 0.96px solid #000000;
    border-radius: 5.12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btnImg {
      width: 20px;
      height: 20px;
    }
    .btnImg1 {
      width: 20px;
      height: 20px;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      filter: gray;
    }
    .btnText {
      font-size: 10.08px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #26b6f0;
      margin-top: 6.4px;
    }
    .btnText1 {
      font-size: 10.08px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 6.4px;
    }
  }
}
.VRBox {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 20;
  transition: all 0.3s;
  .closeImg {
    width: 2vh;
    height: 2vh;
    position: absolute;
    z-index: 22;
    top: 2%;
    right: 2%;
  }
  .playBox {
    width: 100%;
    height: 100vh;
  }
}
</style>